/*
|--------------------------------------------------------------------------
| Progress Bar
|--------------------------------------------------------------------------
*/

progressBar = {
    el: null,
    init: function() {
        if (this.el) { $(this.el).remove(); }
        this.el = $('<div class="progress-bar-container"><div class="progress-bar"></div></div>').appendTo('body');
    },
    update: function(percent) {
        $(this.el).find('.progress-bar').css({ width: percent + '%' });
    },
    finish: function() {
        $(this.el).remove();
    }
};


/*
|--------------------------------------------------------------------------
| Ajax settings
|--------------------------------------------------------------------------
*/

$.fn.ageAxe.defaults.start = function() {
    progressBar.init();
};
$.fn.ageAxe.defaults.success = function() {
    formStatus.success();
};
$.fn.ageAxe.defaults.always = function() {
    progressBar.finish();
};
$.fn.ageAxe.defaults.updateProgress = function(percent) {
    progressBar.update(percent);
};
$.fn.ageAxe.defaults.error = function(response, instance) {
    if (response.responseText === 'Session Expired') {
        alert('Your session has expired. Please refresh the page and try again.');
    }

    if(response && response.responseJSON && response.responseJSON.errors) {
        instance.insertFormErrors(response.responseJSON.errors);
        formStatus.error();
    } else {
        alert('An error occurred.  Please try again later.');
    }
};



/*
|--------------------------------------------------------------------------
| Form Image plugin
|--------------------------------------------------------------------------
*/
$('.form-image').formImage();

/*
|--------------------------------------------------------------------------
| Vue directives
|--------------------------------------------------------------------------
*/

Vue.directive('tooltip', function(title) {
    $(this.el).tooltip({ title: title });
});

/*å
|--------------------------------------------------------------------------
| Refresh CSRF token
|--------------------------------------------------------------------------
*/

setInterval(refreshToken, 1200000);
function refreshToken() {
    $.get('/refresh-token', function(response) {
        $('meta[name="token"]').attr('content', response);
    });
}

